import { ENV_CONSTANTS } from '../Configs/Constants';
import { NUMBER_HELPERS } from './Numbers';
import { HELPERS } from '.';

/**
 * This is to sum all amouts value for eatch days
 * @param {Object} data
 */
export const getTotalLiquidityAmount = async (data, priceUnit) => {
  const total = NUMBER_HELPERS.toDecimal(
    data ? data.map(item => item.amount).reduce((a, b) => a + b, 0) : 0,
    6,
  );

  // Const totalPrice = await HELPERS.getTokenPrice(
  //   ENV_CONSTANTS.USD.NAME,
  //   priceUnit,
  //   total,
  // );

  return HELPERS.balancePipe(Number(total));
};

/**
 * This is to sum all amouts value for eatch days
 * @param {Object} data
 */
export const getTotalInvoiceFundedAmount = async (data, priceUnit) => {
  const total = data
    ? data.map(item => Number(item.invoiceAmount)).reduce((a, b) => a + b, 0)
    : 0;

  // Const totalPrice = await HELPERS.getTokenPrice(
  //   ENV_CONSTANTS.USD.NAME,
  //   priceUnit,
  //   total,
  // );

  return HELPERS.balancePipe(Number(total));
};
