import { useContext } from 'react';
import { Button, Navbar, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ENV_CONSTANTS } from '../../../Configs/Constants';
import { URLS } from '../../../Configs/FrontendUrls';
import { AppDataContext } from '../../../Contexts/AppData';
import { BrandIcon } from '../../Atoms/Icons/BrandIcon';
import { BrandName } from '../../Atoms/Icons/BrandName';
import { styles } from './Styles';

/**
 * Component will be the main Navigation Bar for the application.
 *
 * @component
 */

export const Header = () => {
  const navigate = useNavigate();
  const { state: appDataState } = useContext(AppDataContext);

  return (
    <Navbar style={styles.headerMain(appDataState.appData.theme)} expand="lg">
      <Navbar.Brand>
        <Button
          className="d-flex me-2 align-items-center"
          style={styles.brandButton()}
          onClick={() => {
            navigate('/');
          }}
        >
          <BrandIcon size={50} url={appDataState.appData.theme.logoUrl} />
          <div className="mx-2">
            <BrandName
              size={50}
              url={appDataState.appData.theme.brandNameDarkUrl}
            />
          </div>
        </Button>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav style={styles.mainRowView()} className="me-auto">
          {URLS.NAV_LINK.map(item => (
            <Nav.Link
              key={item.id}
              target="_blank"
              href={item.link}
              className={item.name === 'Gitbook' ? 'gitbook' : ''}
            >
              {item.name}
            </Nav.Link>
          ))}

          <Nav.Item>
            <div className="mx-4">
              {URLS.SOCIAL_LINK.map(item => (
                <a
                  key={item.id}
                  target="_blank"
                  href={item.link}
                  rel="noreferrer"
                >
                  <img
                    src={item.icon}
                    alt={item.name}
                    style={styles.socialIcon()}
                  />
                </a>
              ))}
            </div>
          </Nav.Item>
          <Nav.Item
            style={styles.actionButtonsView(appDataState.appData.theme)}
          >
            <Button
              href={ENV_CONSTANTS.V1_URL}
              target="_blank"
              style={styles.actionButton(appDataState.appData.theme)}
            >
              VIEW V1
            </Button>
            <Button
              href={ENV_CONSTANTS.V2_URL}
              target="_blank"
              className="ms-4"
              style={styles.actionButton(appDataState.appData.theme)}
            >
              LEND ON V2
            </Button>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
