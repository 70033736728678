import { useContext } from 'react';
import { Card, Col, Button } from 'react-bootstrap';
import { ENV_CONSTANTS } from '../../../../../Configs/Constants';
import { AppDataContext } from '../../../../../Contexts/AppData';
import tradeIcon from '../../../../../Assets/trade.png';
import { styles } from './Styles';
/**
 * Component will be the main Side Bar for the application.
 *
 * @component
 */
export const LendStableCard = () => {
  const { state: appDataState } = useContext(AppDataContext);

  return (
    <Col className="mt-4" sm={12} xs={12} md={6}>
      <Card
        style={styles.mainCard(appDataState.appData.theme)}
        className="h-100"
      >
        <div style={styles.cardParentView()}>
          <div style={styles.contentView()}>
            <h5 style={styles.kpiValue(appDataState.appData.theme)}>
              LEND STABLECOINS
            </h5>
            <span style={styles.contentText(appDataState.appData.theme)}>
              Our lending pools are used to finance the invoices of Small and
              Medium Enterprises (SMEs) across the globe that will completely
              change the face of trade finance.
            </span>
            <div
              className="mt-4"
              style={styles.buttonBackgroundView(appDataState.appData.theme)}
            >
              <Button
                className="lendv2"
                href={ENV_CONSTANTS.V2_URL}
                target="_blank"
                style={styles.lendButton(appDataState.appData.theme)}
              >
                Lend now
              </Button>
              <span style={styles.v2Text(appDataState.appData.theme)}>v2</span>
            </div>
          </div>
          <div style={styles.iconView()}>
            <img src={tradeIcon} alt="tread" style={styles.treadImage()} />
          </div>
        </div>
      </Card>
    </Col>
  );
};
