/**
 * Return styles for Lender Pool Outer Container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function mainHome(theme) {
  const style = {
    backgroundColor: theme.primary.main,
  };
  return style;
}

/**
 * Return styles for pool details Container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function poolDetailMain(theme) {
  const style = {
    borderRadius: 32,
    padding: '28px 28px',
    backgroundColor: theme.primary.backgroundColor,
  };
  return style;
}

/**
 * Return styles for tread button
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function treadButton(theme) {
  const style = {
    backgroundColor: 'transparent',
    color: theme.primary.textColor,
    minWidth: 186,
    fontWeight: '600',
    border: 'none',
    alignItems: 'center',
    display: 'flex',
    paddingRight: 0,
    paddingLeft: '55px',
  };
  return style;
}

/**
 * Return styles for brand logo
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function brandLogo(theme) {
  const style = {
    height: 16,
    width: 16,
    color: theme.primary.textColor,
    marginRight: '2em',
  };
  return style;
}

/**
 * Return styles for pool Title
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function poolTitle(theme) {
  const style = {
    fontWeight: '600',
    fontSize: 24,
    color: theme.primary.textColor,
  };
  return style;
}

function tradeCard(theme) {
  const style = {
    backgroundColor: theme.primary.backgroundColor,
  };
  return style;
}

export const styles = {
  mainHome,
  poolDetailMain,
  treadButton,
  brandLogo,
  poolTitle,
  tradeCard,
};
