import { useContext } from 'react';
import { Card, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { SvgElementWrapper } from '../../../SvgElementWrapper';
import { ICONS } from '../../../SvgElementWrapper/IconsConstants';
import { APP_DATA } from '../../../../../Configs/AppData';
import { styles } from './Styles';

/**
 * Component will be the main Side Bar for the application.
 *
 * @component
 */
export const OverviewKpi = ({ kpiData, icon }) => {
  const { state: appDataState } = useContext(AppDataContext);

  return (
    <Col sm={6} xs={6} xl={3} md={4}>
      {kpiData && (
        <Card style={styles.mainCard(appDataState.appData.theme)}>
          <div style={styles.iconBackground(appDataState.appData.theme)}>
            <img
              alt={kpiData.head}
              style={styles.iconView(appDataState.appData.theme)}
              src={icon}
            />
          </div>

          <div className="ms-4">
            <h5 style={styles.kpiValue(appDataState.appData.theme)}>
              {kpiData.head}
            </h5>

            <div className="d-flex align-items-center">
              <span style={styles.contentText(appDataState.appData.theme)}>
                {kpiData.content}
                {kpiData.info && (
                  <OverlayTrigger
                    placement="auto"
                    overlay={<Tooltip>{kpiData.info}</Tooltip>}
                  >
                    <div style={styles.infoView()}>
                      <SvgElementWrapper
                        name={ICONS.INFOICON}
                        height={16}
                        width={16}
                        fillColor={APP_DATA.LIGHT_THEME.infoIcon}
                      />
                    </div>
                  </OverlayTrigger>
                )}
              </span>
            </div>
          </div>
        </Card>
      )}
    </Col>
  );
};
