export const FacebookIocn = ({ height, width, fillColor, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.51351 0.5C4.26694 0.5 0.0136719 4.75336 0.0136719 9.99984C0.0136719 15.2461 4.26694 19.5 9.51351 19.5C14.7601 19.5 19.0137 15.2463 19.0137 9.99984C19.0135 4.75336 14.7601 0.5 9.51351 0.5ZM12.7579 6.57681C12.7579 6.66748 12.6844 6.74107 12.5936 6.74107H10.2809V7.98403H12.5934C12.6843 7.98403 12.7577 8.05762 12.7577 8.14829V10.4606C12.7577 10.5514 12.6843 10.6248 12.5934 10.6248H10.4452V15.6992C10.4452 15.79 10.3718 15.8634 10.2809 15.8634H8.09542C8.00459 15.8634 7.93116 15.79 7.93116 15.6992V10.625H6.43357C6.34273 10.625 6.26931 10.5516 6.26931 10.4607V8.14846C6.26931 8.05778 6.34273 7.9842 6.43357 7.9842H7.93116V7.0466C7.93116 7.03313 7.9328 7.02015 7.93576 7.00767C7.93707 5.67207 8.22273 4.14412 10.4109 4.13722C10.4156 4.13689 10.4207 4.13656 10.4257 4.13656H12.5934C12.6843 4.13656 12.7577 4.21015 12.7577 4.30082L12.7579 6.57681Z"
      fill={fillColor}
    />
  </svg>
);
