import { REQUEST_METHODS } from '../Configs/RequestMethods';
import { ROUTE_NAMES } from '../Configs/BackendUrls';
import { apiHandler } from '../Utils/ApiHandler';

// All API request call apiHandler (helper function)

/**
 *
 * @returns access Transaction Land data
 */
export const getInvoiceFunded = async () => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.GET,
      ROUTE_NAMES.GET_INVOICE_FUNDED,
      {},
      null,
      true,
    );

    return response;
  } catch (error) {
    return error;
  }
};
