import { Container, ThemeProvider } from 'react-bootstrap';
import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import { AppDataContext } from '../../Contexts/AppData';
import { Header } from '../Molecules/Header/Header';
import { COMMON } from '../../Configs/Common';
import 'react-toastify/dist/ReactToastify.css';
import { checkScreenResoluction } from '../../Actions/AppData';
import { RoutesContent } from './RoutesContent';
import { styles } from './Styles';

/**
 * Component wrap the whole application unify the layout.
 *
 * @component
 */
export const MainLayout = () => {
  // Get use of AppDataContext that holds the application needed data
  const { state: appDataState, dispatch } = useContext(AppDataContext);

  useEffect(() => {
    checkScreenResoluction(dispatch);
  }, []);

  return (
    <ThemeProvider breakpoints={['xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
      <Helmet title={COMMON.APP_NAME}>
        <link rel="icon" href={COMMON.FAVICON} />
      </Helmet>
      <Container fluid="true" style={{ display: 'flex' }}>
        <div style={styles.mainWrapper(appDataState.appData.theme)}>
          <Header />
          <ToastContainer
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            position="top-right"
            autoClose={COMMON.NOTIFICATION_AUTO_CLOSE_MS}
            theme="light"
            hideProgressBar={false}
            newestOnTop={false}
            rtl={false}
          />
          <RoutesContent />
        </div>
      </Container>
    </ThemeProvider>
  );
};
