import axios from 'axios';
import { ENV_CONSTANTS } from '../Configs/Constants';
import { RESPONSE_CODES } from '../Configs/ResponseCodes';
import { HELPERS } from '../Helpers';

/**
 *
 * ApiHandler: Is a helper function used to perform all API calls to the server and centralize success and error handling
 * This helper function will use the base URL of the server
 * Create an API instance to perform all types of requests
 * It takes 5 parameters:
 * method: Switched parameter, for define the request method type
 * url: Any additional url appending to the base URL
 * requestBody: Data will be sent to the server
 * headers: Any additional headers to be sent to the server
 */

export const apiHandler = async (
  method,
  url,
  headers = {},
  requestBody = {},
  isV1 = false,
) => {
  try {
    const Headers = {
      'Content-Type': 'application/json',
    };
    const baseURL = `${
      isV1 ? ENV_CONSTANTS.API_V1_SERVER_URL : ENV_CONSTANTS.API_SERVER_URL
    }${url}`;
    const data = {
      method,
      url: baseURL,
      headers: {
        ...Headers,
        ...headers,
      },
      data: JSON.stringify(requestBody),
    };
    return axios(data)
      .then(response => response.data)
      .then(response => {
        switch (response.code) {
          case RESPONSE_CODES.SUCCESS:
            return HELPERS.responseHandler(response);
          case RESPONSE_CODES.UNAUTHORIZED:
          case RESPONSE_CODES.BAD_REQUEST:
            return false;
          default:
            return HELPERS.responseHandler(response);
        }
      })
      .catch(error => error);
  } catch (error) {
    return error;
  }
};
