import { useContext } from 'react';
import { Card, Col, Button } from 'react-bootstrap';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { ENV_CONSTANTS } from '../../../../../Configs/Constants';
import { styles } from './Styles';
/**
 * Component will be the main Side Bar for the application.
 *
 * @component
 */
export const InvoiceFactoring = () => {
  const { state: appDataState } = useContext(AppDataContext);

  return (
    <Col md={12} className="mb-4">
      <Card style={styles.mainCard(appDataState.appData.theme)}>
        <p style={styles.invoiceFactoringTitle()}>
          Invoice <br />
          Factoring
        </p>
        <span
          className="me-4"
          style={styles.contentText(appDataState.appData.theme)}
        >
          Polytrade brings safe and insurance backed real world assets to crypto
          world. We provide borrowers access to low interest and swift financing
          to free up critical working capital.
        </span>
        <Button
          className="visit"
          href={ENV_CONSTANTS.INVOICE_FACTORING_URL}
          target="_blank"
          style={styles.visitButton(appDataState.appData.theme)}
        >
          Visit
        </Button>
      </Card>
    </Col>
  );
};
