import { useContext, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { getTransactionLend } from '../../../APIs/TransactionsServices';
import { getInvoiceFunded } from '../../../APIs/InvoiceFundedServices';
import filledAmount from '../../../Assets/filled_amount.svg';
import brandIcon from '../../../Assets/fill_brand_icon.svg';
import {
  getTotalInvoiceFundedAmount,
  getTotalLiquidityAmount,
} from '../../../Helpers/Liquidity';
import { HELPERS } from '../../../Helpers';
import { ENV_CONSTANTS } from '../../../Configs/Constants';
import { AppDataContext } from '../../../Contexts/AppData';
import { OverviewKpi } from './Components/OverviewKpi/OverviewKpi';
import { styles } from './Styles';

/**
 * Wrapper for overview section in homepage
 * @returns {ReactElement}
 */
export const Overview = () => {
  const { state: appDataState } = useContext(AppDataContext);
  const [liquidity, setLiquidity] = useState([]);
  const [invoiceFunded, setInvoiceFunded] = useState([]);
  const [totalInvoiceFundedPrice, setTotalInvoiceFundedPrice] = useState(0);
  const [totalLiquidityPrice, setTotalLiquidityPrice] = useState(0);
  const [tradePrice, setTradePrice] = useState(0);

  const getTransactionLendData = () => {
    getTransactionLend().then(data => {
      setLiquidity(data.data);
    });
  };

  const getInvoiceFundedData = () => {
    getInvoiceFunded().then(data => {
      setInvoiceFunded(data.data);
    });
  };

  useEffect(() => {
    const getInvoiceFundedTotalPrice = async () => {
      const response = await getTotalInvoiceFundedAmount(
        invoiceFunded,
        ENV_CONSTANTS.USD.NAME,
      );
      setTotalInvoiceFundedPrice(response);
    };

    getInvoiceFundedTotalPrice();
  }, [invoiceFunded]);

  useEffect(() => {
    const getLiquidityTotalPrice = async () => {
      const response = await getTotalLiquidityAmount(
        liquidity,
        ENV_CONSTANTS.USD.NAME,
      );
      setTotalLiquidityPrice(response);
    };

    getLiquidityTotalPrice();
  }, [liquidity]);

  const getTradePrice = async () => {
    HELPERS.getTokenPrice(
      ENV_CONSTANTS.TRADE.SYMBOL,
      ENV_CONSTANTS.USD.NAME,
      ENV_CONSTANTS.DEFAULT_TRADE_AMOUNT,
    ).then(response => {
      const tradePriceAmount = response;
      setTradePrice(tradePriceAmount);
    });
  };

  useEffect(() => {
    getTransactionLendData();
    getInvoiceFundedData();
    getTradePrice();
  }, []);

  const liquidityData = {
    head: `${ENV_CONSTANTS.USD.SYMBOL}${HELPERS.formatInCurrency(
      totalLiquidityPrice + ENV_CONSTANTS.CONSTANT_LIQUIDITY,
    )}`,
    content: 'Total Deposits',
    info: ENV_CONSTANTS.INFO_DETAILS.TOTAL_LIQUIDITY,
  };

  const fundedData = {
    head: `${ENV_CONSTANTS.USD.SYMBOL}${HELPERS.formatInCurrency(
      totalInvoiceFundedPrice,
    )}`,
    content: 'Total Invoices Funded',
    info: ENV_CONSTANTS.INFO_DETAILS.INVOICE_FUNDED,
  };

  const treadData = {
    head: `${ENV_CONSTANTS.USD.SYMBOL}${HELPERS.formatInCurrency(tradePrice)}`,
    content: 'TRADE Price',
  };

  const totalSupplyData = {
    head: `${HELPERS.formatInCurrency(ENV_CONSTANTS.TOTAL_SUPPLY)}`,
    content: 'Total Supply',
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h4 style={styles.poolTitle(appDataState.appData.theme)}>Overview</h4>
      </div>
      <Row>
        <OverviewKpi icon={filledAmount} kpiData={liquidityData} />
        <OverviewKpi icon={filledAmount} kpiData={fundedData} />
        <OverviewKpi icon={brandIcon} kpiData={treadData} />
        <OverviewKpi icon={brandIcon} kpiData={totalSupplyData} />
      </Row>
    </div>
  );
};
