export const LinkdinIcon = ({ height, width, fillColor, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4998 0C4.9773 0 0.5 4.4773 0.5 9.99983C0.5 15.5225 4.97712 20 10.4998 20C16.0227 20 20.5002 15.5225 20.5002 9.99983C20.5 4.4773 16.0227 0 10.4998 0ZM6.35036 4.51015C7.03059 4.51015 7.58234 5.01331 7.58234 5.63406C7.58234 6.2548 7.03059 6.75796 6.35036 6.75796C5.6698 6.75796 5.11839 6.2548 5.11839 5.63406C5.11839 5.01331 5.6698 4.51015 6.35036 4.51015ZM7.59168 14.123H5.15747V7.77121H7.59168V14.123ZM15.8633 14.123H13.4173V10.6303C13.4173 10.2315 13.3433 9.94934 13.1958 9.78369C13.0482 9.61839 12.8408 9.53539 12.574 9.53539C12.2789 9.53539 12.0394 9.64709 11.8561 9.87049C11.6727 10.0939 11.5812 10.4945 11.5812 11.0727V14.123H9.14682V7.77121H11.4135V8.80572C11.7522 8.3833 12.0953 8.08123 12.4421 7.89968C12.789 7.7183 13.2114 7.62769 13.7099 7.62769C14.3839 7.62769 14.9111 7.82792 15.292 8.22872C15.6726 8.62935 15.8631 9.24837 15.8631 10.0858V14.123H15.8633Z"
      fill={fillColor}
    />
  </svg>
);
