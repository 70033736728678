import { Container, Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HELPERS } from '../../../Helpers';
import { styles } from './Styles';

/**
 * Component to use as an Atom in the application.
 *
 * @component
 * @param {string} type
 * @param {string} title
 * @param {Function} handler
 * @param {boolean} disabled
 * @param {boolean} isLoading
 */
export const CustomButton = ({
  type,
  title = 'A New Custom Button',
  handler = () => {},
  disabled = false,
  isLoading = false,
  className = null,
  to = null,
}) => (
  <Container style={styles.container()}>
    {isLoading && (
      <div className="text-center" style={styles.isLoading()}>
        <Spinner size="sm" animation="border" variant="secondary" />
      </div>
    )}
    {HELPERS.isEmpty(to) ? (
      <Button
        style={styles.customButton(type)}
        size="md"
        disabled={disabled}
        className={className}
        onClick={handler}
      >
        {title}
      </Button>
    ) : (
      <Link
        style={styles.customButton(type)}
        size="md"
        disabled={disabled}
        className={className}
        to={to}
      >
        {title}
      </Link>
    )}
  </Container>
);
