import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { AppDataProvider } from './Contexts/AppData';
import { MainLayout } from './Components/MainLayout/MainLayout';
import ErrorBoundary from './Components/ErrorBoundary/ErrorBoundary';
import Store from './Store/Store';
import './Assets/fonts/Inter-Medium.ttf';
import { ENV_CONSTANTS } from './Configs/Constants';

/**
 * Component wrap the whole application unify the layout.
 *
 * @component
 */

const App = () => {
  // Declaring Google Tag Manager ID
  const tagManagerArguments = {
    gtmId: ENV_CONSTANTS.GTM_ID,
  };

  // Initializing  Google Analytics
  useEffect(() => {
    if (ENV_CONSTANTS.CURRENT_ENV === ENV_CONSTANTS.PRODUCTION_ENV) {
      ReactGA.initialize(ENV_CONSTANTS.GA_TRACKING_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  // Initializing Google Tag Manager
  useEffect(() => {
    if (ENV_CONSTANTS.CURRENT_ENV === ENV_CONSTANTS.PRODUCTION_ENV) {
      TagManager.initialize(tagManagerArguments);
    }
  });
  return (
    <Store>
      <ErrorBoundary>
        <AppDataProvider>
          <Router>
            <MainLayout />
          </Router>
        </AppDataProvider>
      </ErrorBoundary>
    </Store>
  );
};

export default App;
