import { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { AppDataContext } from '../../Contexts/AppData';
import { Home } from '../Templates/Home/Home';
import { URLS } from '../../Configs/FrontendUrls';
import { NotFound } from '../General/NotFound';
import { styles } from './Styles';

/**
 * Routes Component to store the routes for navigation.
 *
 * @component
 */
export const RoutesContent = () => {
  // Get use of AppDataContext that holds the application needed data
  const { state: appDataState } = useContext(AppDataContext);
  return (
    <Container
      fluid="true"
      style={styles.mainContainer(appDataState.appData.theme)}
    >
      <Routes>
        <Route exact path={URLS.URL.HOME} element={<Home />} />
        <Route path={URLS.URL.DEFAULT} element={<NotFound />} />
      </Routes>
    </Container>
  );
};
