/**
 * Return styles for Lender Pool Outer Container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function mainCard(theme) {
  const style = {
    width: '100%',
    backgroundColor: '#dbe0f8',
    padding: '24px 35px',
    border: 'none',
    borderRadius: '0px 0px 20px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  };
  return style;
}

/**
 * Return styles for content text
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function contentText(theme) {
  const style = {
    color: theme.primary.textColor,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    fontWeight: '500',
    width: '100%',
  };
  return style;
}

/**
 * Return styles for button Background View
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function visitButton(theme) {
  const style = {
    width: 116,
    height: 50,
    color: theme.primary.backgroundColor,
    backgroundColor: theme.primary.textColor,
    borderRadius: 30,
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  return style;
}

/**
 * Return styles for tsipce Icon
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function tsipceIcon() {
  const style = {
    height: 50,
    width: 110,
  };
  return style;
}

/**
 * Return styles for invoice factoring title
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function invoiceFactoringTitle() {
  const style = {
    fontSize: '22px',
    fontWeight: 700,
    paddingRight: '33px',
    margin: '0px',
    lineHeight: '20px',
  };
  return style;
}

export const styles = {
  mainCard,
  contentText,
  visitButton,
  tsipceIcon,
  invoiceFactoringTitle,
};
