const APR = 'APR';
const FACEBOOK = 'FACEBOOK';
const INSTA = 'INSTA';
const LINKDIN = 'LINKDIN';
const TWITER = 'TWITER';
const TELEGRAM = 'TELEGRAM';
const TSPICE = 'TSPICE';
const INFOICON = 'INFOICON';

/**
 * Export all svg icon for app.
 * @constant {Object} ACTION_TYPES .
 */
export const ICONS = {
  APR,
  FACEBOOK,
  INSTA,
  LINKDIN,
  TWITER,
  TELEGRAM,
  TSPICE,
  INFOICON,
};
