/**
 * Return styles for Lender Pool Outer Container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function mainCard(theme) {
  const style = {
    width: '100%',
    backgroundColor: theme.primary.backgroundColor,
    // Padding: '24px 24px',
    border: 'none',
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'start',
    height: 280,
  };
  return style;
}

/**
 * Return styles for card Parent View
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function cardParentView(theme) {
  const style = {
    display: 'flex',
    height: '100%',
  };
  return style;
}

/**
 * Return styles for contentView
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function contentView(theme) {
  const style = {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 42,
  };
  return style;
}

/**
 * Return styles for kpi values
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function kpiValue(theme) {
  const style = {
    marginTop: 16,
    color: theme.primary.textColor,
    fontSize: 24,
    fontWeight: '600',
  };
  return style;
}

/**
 * Return styles for info icon container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function infoView(theme) {
  const style = {
    height: 12,
    width: 12,
    borderRadius: 20,
    backgroundColor: theme.primary.textColor,
    justifyContent: 'center',
    alignItems: 'center',
  };
  return style;
}

/**
 * Return styles for content text
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function contentText(theme) {
  const style = {
    // Width: 'max-content',
    color: theme.primary.gray,
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
  };
  return style;
}

/**
 * Return styles for info icon
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function infoIcon(theme) {
  const style = {
    width: 'max-content',
    color: theme.primary.gray,
    cursor: 'pointer',
  };
  return style;
}

/**
 * Return styles for button Background View
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function buttonBackgroundView(theme) {
  const style = {
    width: 178,
    height: 40,
    backgroundColor: theme.secondary.main,
    borderRadius: 25,
  };
  return style;
}

/**
 * Return styles for button Background View
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function lendButton(theme) {
  const style = {
    width: 122,
    height: 40,
    color: theme.primary.backgroundColor,
    backgroundColor: theme.primary.textColor,
    borderRadius: 25,
    border: 'none',
  };
  return style;
}

/**
 * Return styles for button Background View
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function v2Text(theme) {
  const style = {
    color: theme.primary.textColor,
    marginLeft: 16,
  };
  return style;
}

/**
 * Return styles for button Background View
 * @returns {CSSProperties}
 */
function iconView() {
  const style = {
    width: 236,
    paddingTop: 22,
  };
  return style;
}

/**
 * Return styles for button Background View
 * @returns {CSSProperties}
 */
function treadImage() {
  const style = {
    height: 300,
    width: '100%',
  };
  return style;
}

export const styles = {
  mainCard,
  kpiValue,
  infoView,
  contentText,
  infoIcon,
  buttonBackgroundView,
  lendButton,
  v2Text,
  cardParentView,
  contentView,
  iconView,
  treadImage,
};
