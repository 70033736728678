import { COMMON } from '../../../Configs/Common';

const appName = COMMON.APP_NAME;

/**
 * Component for Brand Icon will be used in Header.
 *
 * @component
 */
export const BrandIcon = ({ url, size = 30 }) => (
  <img
    src={url}
    width={size}
    height={size}
    className="d-inline-block align-top"
    alt={appName}
  />
);
