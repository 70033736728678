import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useContext } from 'react';
import { Card, Col } from 'react-bootstrap';
import Slider from 'react-slick';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { ENV_CONSTANTS } from '../../../../../Configs/Constants';
import { styles } from './Styles';

/**
 * Component will be the main Side Bar for the application.
 *
 * @component
 */
export const BuyTreadSlider = () => {
  const { state: appDataState } = useContext(AppDataContext);

  const imgSlides = () =>
    ENV_CONSTANTS.BUY_TREAD.map(item => (
      <a key={item.id} target="_blank" href={item.link} rel="noreferrer">
        <div className="imgpad">
          <img
            style={{ height: 90, width: 250 }}
            alt="slides"
            src={item.logo}
            width="100%"
          />
        </div>
      </a>
    ));

  return (
    <Col className="mt-4" md={12}>
      <Card
        style={styles.mainCard(appDataState.appData.theme)}
        className="h-100"
      >
        <div className="d-flex justify-content-center">
          <h5 style={styles.kpiValue(appDataState.appData.theme)}>
            BUY $TRADE
          </h5>
        </div>
        <Col md={12}>
          <div
            className="mt-5"
            style={{ width: window.innerWidth - 160, overflow: 'hidden' }}
          >
            <Slider
              autoplay
              dots={false}
              slidesToShow={5}
              slidesToScroll={1}
              arrows={false}
              autoplaySpeed={2000}
            >
              {imgSlides()}
            </Slider>
          </div>
        </Col>
      </Card>
    </Col>
  );
};
