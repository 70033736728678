import ReactDOMClient from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import App from './App';

/**
 * Get the container of the application .
 * @constant {Element} container .
 */
const container = document.querySelector('#root');

/**
 * Create the root element .
 * @constant {ReactDOMClient.Root} root .
 */
const root = ReactDOMClient.createRoot(container);

/**
 * Void
 * Render the main component App to the root element .
 * @function Root.render .
 */
root.render(<App />);
