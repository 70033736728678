/**
 * Return styles for pool Title
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function poolTitle(theme) {
  const style = {
    fontWeight: '600',
    fontSize: 24,
    color: theme.primary.textColor,
  };
  return style;
}

export const styles = {
  poolTitle,
};
