import { ENV_CONSTANTS } from '../../Configs/Constants';

/**
 * Return styles for main container
 * @returns {CSSProperties}
 * MainLayout styles use the theme to set the proper colors.
 * MainContainer style will hold all other components.
 * @param {Theme} theme .
 */
function mainContainer(theme) {
  const style = {
    minHeight: 'calc(100vh - 116px)',
    margin: 0,
    padding: 44,
    backgroundColor: theme.primary.main,
    color: theme.primary.textColor,
  };
  return style;
}

function mainWrapper(theme) {
  const style = {
    flex: '1 1 auto',
    display: 'flex',
    height: '100%',
    backgroundColor: theme.primary.backgroundColor,
    flexDirection: 'column',
    transition: 'all 0.5s ease-in-out',
  };
  return style;
}

export const styles = { mainContainer, mainWrapper };
