export const InstaIcon = ({ height, width, fillColor, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5141 6.34296H11.9717V7.93276H13.5141V6.34296Z"
      fill="#99A3AC"
    />
    <path
      d="M12.567 9.98259C12.567 11.394 11.4192 12.5425 10.0086 12.5425C8.59774 12.5425 7.44994 11.394 7.44994 9.98259C7.44994 9.60063 7.53634 9.23908 7.68686 8.91315H5.99609V13.3075C5.99609 13.7064 6.32046 14.0309 6.71913 14.0309H13.298C13.6966 14.0309 14.021 13.7064 14.021 13.3075V8.91332H12.3301C12.4806 9.23925 12.567 9.60063 12.567 9.98259Z"
      fill="#99A3AC"
    />
    <path
      d="M10.0081 11.5051C10.8469 11.5051 11.5297 10.8221 11.5297 9.98259C11.5297 9.56605 11.3614 9.18842 11.0895 8.91315H8.9263C8.65464 9.18824 8.48633 9.56605 8.48633 9.98259C8.48633 10.8221 9.16892 11.5051 10.0081 11.5051Z"
      fill="#99A3AC"
    />
    <path
      d="M10.0077 0C4.48822 0 0.0136719 4.4773 0.0136719 10C0.0136719 15.5225 4.48822 20.0004 10.0077 20.0004C15.5273 20.0004 20.0022 15.5227 20.0022 10C20.002 4.4773 15.5273 0 10.0077 0ZM15.0571 13.3078C15.0571 14.2786 14.2677 15.0687 13.2972 15.0687H6.71832C5.748 15.0687 4.95844 14.2788 4.95844 13.3078V6.69486C4.95844 5.72397 5.74783 4.93395 6.71832 4.93395H13.2972C14.2675 4.93395 15.0571 5.7238 15.0571 6.69486V13.3078Z"
      fill="#99A3AC"
    />
  </svg>
);
