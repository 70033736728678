import { ethers } from 'ethers';
import { HELPERS } from './index';
/**
 *
 * @param {string} value
 * @returns {boolean}
 */
const isNumber = value => /^-?\d+$/.test(value);

/**
 *
 * @param {string} value
 * @returns {boolean}
 */
const isBigNumber = value => {
  const maxIntegerLength = Number.MAX_SAFE_INTEGER.toString().length;
  return value.length > maxIntegerLength;
};

/**
 * Function to check if input string is signed/unsigned integer.
 * Example:
 * Input "12345" returns true.
 * Input "+12345" returns true.
 * Input "-12345" returns true.
 * Input "12E45" returns false.
 */
const isValidInteger = numberString => {
  // If not empty and number-string contains only digits
  const digitsRegex = /^[+-]?\d+$/;
  if (!HELPERS.isEmpty(numberString) && digitsRegex.test(numberString)) {
    return true;
  }

  return false;
};

/**
 * Function to check if input string is signed/unsigned float.
 * Example:
 * Input "12345" returns true.
 * Input "+12345" returns true.
 * Input "-12345" returns true.
 * Input "12345.123" returns true.
 * Input "+12345.123" returns true.
 * Input "-12345.123" returns true.
 * Input "12E45" returns false.
 */
const isValidFloat = numberString => {
  // If not empty and number-string contains only digits
  const floatRegex = /^[-+]?\d*(\.\d+)?$/;
  if (!HELPERS.isEmpty(numberString) && floatRegex.test(numberString)) {
    return true;
  }

  return false;
};

/**
 * Function to check if input string is unsigned integer(Whole Number).
 * Example:
 * Input "12345" returns true.
 * Input "+12345" returns false.
 * Input "-12345" returns false.
 * Input "12E45" returns false.
 */
const isValidWholeNumber = numberString => {
  // If not empty and number-string contains only digits
  const digitsRegex = /^\d+$/;
  if (!HELPERS.isEmpty(numberString) && digitsRegex.test(numberString)) {
    return true;
  }

  return false;
};

/**
 * Todo: to convert to Decimal
 * @param {*} value
 * @param {*} decimal
 * @returns
 */
const toDecimal = (value, decimal) =>
  Number(ethers.utils.formatUnits(value, decimal));

export const NUMBER_HELPERS = {
  isNumber,
  isBigNumber,
  isValidInteger,
  isValidFloat,
  isValidWholeNumber,
  toDecimal,
};
