import { HELPERS } from '../../../../../Helpers';

/**
 * Return styles for Lender Pool Outer Container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function mainCard(theme) {
  const style = {
    width: '100%',
    backgroundColor: theme.primary.backgroundColor,
    padding: '24px 24px',
    border: 'none',
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    flexDirection: 'row',
  };
  return style;
}

/**
 * Return styles for icon background Container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function iconBackground(theme) {
  const style = {
    height: 60,
    minWidth: 60,
    backgroundImage: HELPERS.appGradient(theme),
    borderRadius: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  return style;
}

/**
 * Return styles for icon view Container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function iconView() {
  const style = {
    height: 32,
    width: 32,
  };
  return style;
}

/**
 * Return styles for kpi values
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function kpiValue(theme) {
  const style = {
    marginTop: 16,
    color: theme.primary.textColor,
    fontSize: 24,
    fontWeight: '600',
  };
  return style;
}

/**
 * Return styles for info icon right alignment
 * @returns {CSSProperties}
 */
function infoView(theme) {
  const style = {
    marginLeft: '5px',
    cursor: 'pointer',
  };
  return style;
}

/**
 * Return styles for content text
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function contentText(theme) {
  const style = {
    color: theme.primary.gray,
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
  };
  return style;
}

/**
 * Return styles for info icon
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function infoIcon(theme) {
  const style = {
    width: 'max-content',
    color: theme.primary.gray,
    cursor: 'pointer',
  };
  return style;
}

export const styles = {
  mainCard,
  iconBackground,
  kpiValue,
  infoView,
  contentText,
  infoIcon,
  iconView,
};
