import { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { AppDataContext } from '../../../Contexts/AppData';
import { Overview } from '../../Molecules/Overview/Overview';
import { BuyTreadSlider } from './Components/BuyTreadSlider/BuyTreadSlider';
import { InvoiceCard } from './Components/InvoiceCard/InvoiceCard';
import { InvoiceFactoring } from './Components/InvoiceFactoring/InvoiceFactoring';
import { LendStableCard } from './Components/LendStableCard/LendStableCard';
import { PartnerSlider } from './Components/PartnerSlider/PartnerSlider';
import { TspiceCard } from './Components/TspiceCard/TspiceCard';
import { styles } from './Styles';

/**
 * Component to be the first component to show.
 *
 * @component
 */
export const Home = () => {
  const { state: appDataState } = useContext(AppDataContext);

  return (
    <div style={styles.mainHome(appDataState.appData.theme)}>
      <Row>
        <Col md={12}>
          <Overview />
        </Col>
        <LendStableCard />
        <InvoiceCard />
        <TspiceCard />
        <InvoiceFactoring />
        <BuyTreadSlider />
        <PartnerSlider />
      </Row>
    </div>
  );
};
