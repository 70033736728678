/**
 * Hold all messages used in the application.
 */

const NOT_FOUND = 'No page found. Please try again';
const ERROR = 'Something went wrong';
const NOT_FOUND_CONTENT =
  'The page you are looking for was moved, removed, renamed or might never existed!';
/**
 * Export all the messages in one constant.
 * @constant {Object} MESSAGES .
 */
export const MESSAGES = {
  NOT_FOUND,
  NOT_FOUND_CONTENT,
  ERROR,
};
