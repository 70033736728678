import { useContext } from 'react';
import { Card, Col, Button } from 'react-bootstrap';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { BrandName } from '../../../../Atoms/Icons/BrandName';
import { BrandIcon } from '../../../../Atoms/Icons/BrandIcon';
import Ivy from '../../../../../Assets/ivy.svg';
import ivySlip from '../../../../../Assets/invoice-factoring.png';
import { ENV_CONSTANTS } from '../../../../../Configs/Constants';
import { styles } from './Styles';

/**
 * Component will be the main Side Bar for the application.
 *
 * @component
 */
export const InvoiceCard = () => {
  const { state: appDataState } = useContext(AppDataContext);
  const ivyInvoiceFactoring = () => {
    window.open(ENV_CONSTANTS.IVY_INVOICE_FACTORING_URL, '_blank');
  };

  return (
    <Col className="mt-4" sm={12} xs={12} md={6}>
      <Card
        style={styles.mainCard(appDataState.appData.theme)}
        className="h-100"
      >
        <div style={styles.cardParentView()}>
          <div style={styles.contentView()}>
            <div className="d-flex flex-row">
              <BrandIcon size={25} url={appDataState.appData.theme.logoUrl} />
              <div className="mx-2">
                <BrandName
                  size={25}
                  url={appDataState.appData.theme.brandNameDarkUrl}
                />
              </div>
            </div>
            <img src={Ivy} alt="ivy" style={styles.ivyIcon()} />
            <span style={styles.contentText(appDataState.appData.theme)}>
              The one-stop invoice management solution On chain. Create and send
              invoices to your customers and receive instant payments.
              <br />
              Multi-chain and multi-currency support to ease your business
              needs.
            </span>
            <Button
              className="mt-4 invoice"
              style={styles.lendButton(appDataState.appData.theme)}
              onClick={ivyInvoiceFactoring}
            >
              Raise an invoice
            </Button>
          </div>
          <div style={styles.iconView()}>
            <img src={ivySlip} alt="tread" style={styles.treadImage()} />
          </div>
        </div>
      </Card>
    </Col>
  );
};
