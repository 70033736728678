import { ICONS } from './IconsConstants';
import { AprIcon } from './AprIcon';
import { FacebookIocn } from './FacebookIocn';
import { InstaIcon } from './InstaIcon';
import { LinkdinIcon } from './LinkdinIcon';
import { TwiterIcon } from './TwiterIcon';
import { TelegramIcon } from './TelegramIcon';
import { TspiceIcon } from './TspiceIcon';
import { InfoIcon } from './InfoIcon';

export const SvgElementWrapper = ({
  name,
  height,
  width,
  fillColor,
  className,
}) => {
  const gerIconFromName = () => {
    switch (name) {
      case ICONS.APR:
        return (
          <AprIcon
            className={className}
            height={height}
            width={width}
            fillColor={fillColor}
          />
        );
      case ICONS.FACEBOOK:
        return (
          <FacebookIocn
            className={className}
            height={height}
            width={width}
            fillColor={fillColor}
          />
        );
      case ICONS.INSTA:
        return (
          <InstaIcon
            className={className}
            height={height}
            width={width}
            fillColor={fillColor}
          />
        );
      case ICONS.LINKDIN:
        return (
          <LinkdinIcon
            className={className}
            height={height}
            width={width}
            fillColor={fillColor}
          />
        );
      case ICONS.TWITER:
        return (
          <TwiterIcon
            className={className}
            height={height}
            width={width}
            fillColor={fillColor}
          />
        );
      case ICONS.TELEGRAM:
        return (
          <TelegramIcon
            className={className}
            height={height}
            width={width}
            fillColor={fillColor}
          />
        );
      case ICONS.TSPICE:
        return (
          <TspiceIcon
            className={className}
            height={height}
            width={width}
            fillColor={fillColor}
          />
        );
      case ICONS.INFOICON:
        return (
          <InfoIcon
            className={className}
            height={height}
            width={width}
            fillColor={fillColor}
          />
        );
      default:
        return null;
    }
  };

  return gerIconFromName();
};
