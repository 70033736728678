export const TelegramIcon = ({ height, width, fillColor, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 0C4.9775 0 0.5 4.4775 0.5 10C0.5 15.5225 4.9775 20 10.5 20C16.0225 20 20.5 15.5225 20.5 10C20.5 4.4775 16.0225 0 10.5 0ZM15.4117 6.85083L13.77 14.5842C13.6492 15.1325 13.3225 15.2658 12.8667 15.0075L10.3667 13.1658L9.16167 14.3275C9.045 14.4775 8.86417 14.5733 8.66167 14.5733C8.66 14.5733 8.65917 14.5733 8.6575 14.5733L8.835 12.0283L13.4683 7.84333C13.6683 7.66583 13.4233 7.565 13.1575 7.7425L7.43333 11.3475L4.96667 10.5775C4.43333 10.4083 4.41833 10.0442 5.07917 9.7825L14.7175 6.0675C15.1658 5.90417 15.5558 6.17417 15.4108 6.85167L15.4117 6.85083Z"
      fill={fillColor}
    />
  </svg>
);
