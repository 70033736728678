import { toast } from 'react-toastify';
import { ACTION_TYPES } from '../Configs/ActionTypes';
import { APP_DATA } from '../Configs/AppData';
import { ENV_CONSTANTS } from '../Configs/Constants';
import { MESSAGES } from '../Configs/Messages';

/**
 * Void
 * Toggle theme function dispatches an action to be caught in the reducer
 * After a while, these data will comes from remote API (Backend server)
 * @param {number} currentThemeId
 * @param {Dispatch<any>} dispatch
 * @param {Dispatch} reduxDispatch
 */
export function toggleTheme(currentThemeId, dispatch, reduxDispatch) {
  try {
    // Theme type defined to reflect the whole application needed theme
    let theme;
    // Choose the right theme to apply
    switch (currentThemeId) {
      case 1:
        theme = APP_DATA.DARK_THEME;
        break;
      case 2:
      default:
        theme = APP_DATA.LIGHT_THEME;
        break;
    }

    // Dispatched action to the app Context
    const action = {
      type: ACTION_TYPES.UPDATE_THEME,
      payload: theme,
    };

    dispatch(action);
  } catch {
    toast.error(MESSAGES.ERROR);
  }
}

/**
 * Dispatched action to the app.
 * @param {dispatch} dispatch .
 * Current Status of drawer
 */
export function checkScreenResoluction(dispatch) {
  window.addEventListener('resize', event => {
    let isMobile = window.innerWidth <= ENV_CONSTANTS.MOBILE_BREAK_POINT;
    isMobile = event.target.innerWidth <= ENV_CONSTANTS.MOBILE_BREAK_POINT;
    const action = {
      type: ACTION_TYPES.SET_IS_MOBILE,
      payload: isMobile,
    };
    dispatch(action);
  });
}
