/**
 * Return styles for Lender Pool Outer Container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function mainCard(theme) {
  const style = {
    width: '100%',
    backgroundColor: theme.primary.textColor,
    padding: '24px 35px',
    border: 'none',
    borderRadius: '20px 20px 0px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  };
  return style;
}

/**
 * Return styles for content text
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function contentText(theme) {
  const style = {
    color: theme.primary.backgroundColor,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    fontWeight: '500',
    width: '100%',
  };
  return style;
}

/**
 * Return styles for button Background View
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function visitButton(theme) {
  const style = {
    width: 116,
    height: 50,
    color: theme.primary.textColor,
    backgroundColor: theme.primary.backgroundColor,
    borderRadius: 30,
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  return style;
}

/**
 * Return styles for tsipce Icon
 * @returns {CSSProperties}
 */
function tsipceIcon() {
  const style = {
    height: 50,
    width: 110,
  };
  return style;
}

/**
 * Return styles for spacing top
 * @returns {CSSProperties}
 */
function spacingTop() {
  const style = {
    marginTop: '7%',
  };
  return style;
}

export const styles = {
  mainCard,
  contentText,
  visitButton,
  tsipceIcon,
  spacingTop,
};
