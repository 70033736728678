import { useContext } from 'react';
import { Card, Col, Button } from 'react-bootstrap';
import { AppDataContext } from '../../../../../Contexts/AppData';
import TspiceLogo from '../../../../../Assets/tSpice.png';
import { ENV_CONSTANTS } from '../../../../../Configs/Constants';
import { styles } from './Styles';
/**
 * Component will be the main Side Bar for the application.
 *
 * @component
 */
export const TspiceCard = () => {
  const { state: appDataState } = useContext(AppDataContext);

  return (
    <Col style={styles.spacingTop()} md={12}>
      <Card style={styles.mainCard(appDataState.appData.theme)}>
        <img
          className="me-4"
          src={TspiceLogo}
          alt="TSPICE"
          style={styles.tsipceIcon()}
        />
        <span
          className="me-4"
          style={styles.contentText(appDataState.appData.theme)}
        >
          TSpice brings you access to your liquidity right at your fingertips.
          You can redeem your TSpice from the redeem pool to get your principal
          value back and also stake your TSpice on other pools to get higher
          rewards. Visit the TSpice website to learn more.
        </span>
        <Button
          className="visit"
          href={ENV_CONSTANTS.TSPICE_POLYTRADE}
          target="_blank"
          style={styles.visitButton(appDataState.appData.theme)}
        >
          Visit
        </Button>
      </Card>
    </Col>
  );
};
