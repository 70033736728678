import { REQUEST_METHODS } from '../Configs/RequestMethods';
import { ROUTE_NAMES } from '../Configs/BackendUrls';
import { apiHandler } from '../Utils/ApiHandler';

// All API request call apiHandler (helper function)

/**
 *
 * @returns access Transaction Land data
 */
export const getTransactionLend = async () => {
  try {
    const url = `${ROUTE_NAMES.TRANSACTIONS_LEND}`;
    return await apiHandler(REQUEST_METHODS.GET, url, {});
  } catch (error) {
    return error;
  }
};
