import { Component } from 'react';

/**
 * Component wrap the whole application to handling the uncaught errors.
 *
 * @component
 * @class
 */
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    };
  }

  render() {
    const { hasError, error } = this.state;

    if (hasError) {
      return <h1>{error.message}</h1>;
    }

    const { children } = this.props;

    return children;
  }
}

export default ErrorBoundary;
