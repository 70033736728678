import { HELPERS } from '../../../../../Helpers';

/**
 * Return styles for Lender Pool Outer Container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function mainCard(theme) {
  const style = {
    width: '100%',
    backgroundColor: theme.primary.backgroundColor,
    padding: '24px 24px',
    border: 'none',
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'start',
  };
  return style;
}

/**
 * Return styles for kpi values
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function kpiValue(theme) {
  const style = {
    marginTop: 16,
    color: theme.primary.textColor,
    fontSize: 32,
    fontWeight: '600',
  };
  return style;
}

export const styles = {
  mainCard,
  kpiValue,
};
