/**
 * Return styles for Header Title
 * @returns {CSSProperties}
 * Header styles use the theme to set the proper colors.
 */
const brandButton = () => {
  const style = {
    width: 'max-content',
    backgroundColor: 'transparent',
    border: 'none',
  };
  return style;
};

/**
 * Return styles for Header main container
 * @returns {CSSProperties}
 * Header styles use the theme to set the proper colors.
 */
const headerMain = theme => {
  const style = {
    padding: '15px 44px',
    backgroundColor: '#ECF0FA',
  };
  return style;
};

/**
 * Return styles for Header main container
 * @returns {CSSProperties}
 * Header styles use the theme to set the proper colors.
 */
const mainRowView = theme => {
  const style = {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  };
  return style;
};

/**
 * Return styles for Header main container
 * @returns {CSSProperties}
 * Header styles use the theme to set the proper colors.
 */
const actionButtonsView = theme => {
  const style = {
    flex: 1,
    justifyContent: 'end',
    display: 'flex',
  };
  return style;
};

/**
 * Return styles for Header main container
 * @returns {CSSProperties}
 * Header styles use the theme to set the proper colors.
 */
const actionButton = theme => {
  const style = {
    border: 'none',
    borderRadius: 28,
    backgroundColor: theme.primary.textColor,
    color: theme.primary.backgroundColor,
    height: 50,
    width: 154,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  };
  return style;
};

/**
 * Return styles for social icon
 * @returns {CSSProperties}
 * Header styles use the theme to set the proper colors.
 */
const socialIcon = theme => {
  const style = {
    height: 24,
    width: 24,
    marginRight: 14,
  };
  return style;
};

export const styles = {
  headerMain,
  brandButton,
  actionButtonsView,
  mainRowView,
  actionButton,
  socialIcon,
};
