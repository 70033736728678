/**
 * Helper function to check whether a value is empty(null, undefined, empty string, empty array, empty object)
 * @param {*} value
 * @returns {boolean}
 */
import _ from 'lodash';
import moment from 'moment';
import { ENV_CONSTANTS } from '../Configs/Constants';

const isEmpty = value => {
  if (
    // Undefined
    typeof value === 'undefined' ||
    // String
    (typeof value === 'string' && value.trim().length === 0) ||
    // Null
    getType(value) === '[object Null]' ||
    // Empty object
    (getType(value) === '[object Object]' && Object.keys(value).length === 0) ||
    // Empty array
    (getType(value) === '[object Array]' && value.length === 0)
  ) {
    return true;
  }

  return false;
};

/**
 * Helper function to check when a value is  not empty
 * @param {*} value
 * @returns {boolean}
 */
const isNotEmpty = value => !isEmpty(value);

/**
 *Returns the type of the element
 * @param {*} value
 * @returns value
 */
const getType = value => Object.prototype.toString.call(value);

/**
 *
 * @param {*} element
 * @returns boolean
 */
const isArray = element => {
  if (isNotEmpty(element) && getType(element) === '[object Array]') {
    return true;
  }

  return false;
};

/**
 *
 * @param {*} element
 * @returns boolean
 */
const isObject = element => {
  if (isNotEmpty(element) && getType(element) === '[object Object]') {
    return true;
  }

  return false;
};

const hexColorWithOpacity = (hex, alpha) => {
  const r = Number.parseInt(hex.slice(1, 3), 16);
  const g = Number.parseInt(hex.slice(3, 5), 16);
  const b = Number.parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return `rgb(${r}, ${g}, ${b})`;
};

const appGradient = theme =>
  `linear-gradient(45deg,${theme.common.gradient[0]} ,${theme.common.gradient[1]},${theme.common.gradient[2]})`;

/**
 * Function to build response object
 * @param {*} response
 * @returns response with status code,data,message and error
 */
const responseHandler = response => ({
  status: response.status || response.code || '',
  data: response.data || {},
  message: response.message || '',
  error: response.error || '',
});

const groupByDate = (array, groupKey) => {
  const newArray = _.groupBy(
    array.filter(arrayItem => arrayItem[`${groupKey}`]),
    item => moment(new Date(item[`${groupKey}`])).format('YYYY-MM-DD'),
  );
  return newArray;
};

/**
 * Function check whether email is valid or not
 * @param {*} email
 * @returns boolean
 */
const emailRegex = /^[\w.!#$%&'*+/=?^`{|}~-]+@[a-zA-Z\d-]+(?:\.[a-zA-Z\d-]+)*$/;
const checkValidEmail = email => emailRegex.test(email);

/**
 * Function make first letter capital
 * @param {*} string
 * @returns string
 */
const capitalizeFirstLetter = string => {
  if (!string) {
    return '';
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Function for format number in currency
 * @param {*} string
 * @returns string
 */
const formatInCurrency = number => Number(number).toLocaleString('en-US');

const getTokenPrice = async (token, exchangeTo, amount) => {
  let price = 0;
  try {
    const response = await fetch(
      `https://min-api.cryptocompare.com/data/price?fsym=${token}&tsyms=${exchangeTo}`,
    );

    const unitPrice = Object.values(await response.json())[0];

    price = amount * unitPrice;
  } catch (error) {
    // Will be logged later
    return error;
  }

  return price;
};

/**
 * Returns formatted wallet balance
 * @returns {number}
 * Format the balance and round it to 2 decimals only.
 * @param {number} balance .
 */
const balancePipe = (balance, resolution = ENV_CONSTANTS.TO_FIXED_DECIMALS) =>
  typeof balance === 'number' ? Number(balance?.toFixed(resolution)) : 0;

export const HELPERS = {
  isEmpty,
  isNotEmpty,
  getType,
  isArray,
  responseHandler,
  isObject,
  hexColorWithOpacity,
  checkValidEmail,
  appGradient,
  groupByDate,
  capitalizeFirstLetter,
  formatInCurrency,
  getTokenPrice,
  balancePipe,
};
