// Holds all NAV_LINKS information and URLS
import twitter from '../Assets/social/twitter.svg';
import telegram from '../Assets/social/telegram.svg';
import linkdin from '../Assets/social/linkdin.svg';
import insta from '../Assets/social/insta.svg';
import facebook from '../Assets/social/facebook.svg';

import whitepaper from '../Assets/whitepaper.pdf';

const URL = {
  HOME: '/',
  DEFAULT: '*',
};

const NAV_LINK = [
  {
    id: 1,
    name: 'Home',
    link: 'https://polytrade.finance/',
  },
  {
    id: 2,
    name: 'Whitepaper',
    link: whitepaper,
  },
  {
    id: 3,
    name: 'Gitbook',
    link: 'https://polytrade.gitbook.io/polytrade-gitbook/',
  },
];

const SOCIAL_LINK = [
  {
    id: 1,
    name: 'Twitter',
    link: 'https://twitter.com/Polytrade_fin',
    icon: twitter,
  },
  {
    id: 2,
    name: 'Instagram',
    link: 'https://www.instagram.com/polytrade.finance/',
    icon: insta,
  },
  {
    id: 3,
    name: 'Linkdin',
    link: 'https://www.linkedin.com/company/polytradefin/',
    icon: linkdin,
  },
  {
    id: 4,
    name: 'Facebook',
    link: 'http://facebook.com/Polytrade.finance/',
    icon: facebook,
  },
  {
    id: 5,
    name: 'Telegram',
    link: 'https://t.me/Polytrade_Finance',
    icon: telegram,
  },
];

export const URLS = { URL, NAV_LINK, SOCIAL_LINK };
